<template>
  <div class="h-auto flex items-center md:pr-24">
    <nuxt-link
      :to="contactUrl"
      class="transition-all w-full md:w-auto text-center ease-in-out duration-250 bg-primary-500 hover:bg-primary-600 md:h-[55px] text-button-text rounded-full p-4 font-bold"
    >
      {{ contactLinkText }}
    </nuxt-link>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  isMenuOpen: boolean
}>()

const initData = await useInitData()
const contactUrl = initData.value.siteInfo.contact?.path
const contactLinkText = initData.value.siteInfo.contactLinkText
</script>
