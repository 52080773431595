<template>
  <div
    v-if="businessAreaData"
    class="mb-8 md:mb-0"
    :class="{
      'mobile-only:hidden': inFooter,
    }"
  >
    <ul
      v-if="businessAreaData.footerLinks?.length"
      class="flex-col justify-center items-center text-lg md:font-medium text-header-footer-text md:justify-start md:flex-row flex flex-wrap gap-2 md:text-sm mt-6 md:mt-12"
    >
      <li
        v-for="(item, i) in businessAreaData.footerLinks"
        :key="`link_${i}`"
        class="flex flex-wrap after:hidden md:after:[&:not(:last-child)]:block md:after:content-['|'] md:after:pl-2"
      >
        <nuxt-link
          :to="'https://sv-group.com' + item?.uri?.path"
          class="hover:text-primary-500"
        >
          {{ item?.title }}
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  inFooter: boolean
}>()

const { data: businessAreaData } = await useAsyncGraphqlQuery(
  'businessAreas',
  {
    key: ['SV_GROUP'],
  },
  {
    transform: function (data) {
      const v = data.data.list?.items?.[0]
      if (v && 'address' in v) {
        return v
      }
      return null
    },
  },
)
</script>
