<template>
  <div>
    <PageHeaderMarketing
      :image="data?.siteInfo?.logo"
      @menu:open="menuOpen"
      @menu:close:start="menuCloseStart"
      @menu:close:finished="menuCloseFinish"
    />
    <div
      :class="{
        'pt-[100px]': (isMenuOpen || !hasMenuFinishedClosing) && isLessThanLg,
      }"
    >
      <NuxtPageDependency>
        <Breadcrumb v-if="showBreadcrumb" :links="breadcrumb" />
      </NuxtPageDependency>

      <div>
        <ClientOnly>
          <DrupalMessages v-if="!isEditing" />
        </ClientOnly>

        <slot />
      </div>
    </div>

    <NuxtPageDependency>
      <PageFooterMarketing />
    </NuxtPageDependency>
  </div>
</template>

<script setup lang="ts">
const route = useRoute()
const drupalUser = useDrupalUser()
const language = useCurrentLanguage()
const breadcrumb = useBreadcrumbLinks()
const { isLessThanLg } = useViewport()

const isEditing = computed(
  () =>
    !!(route.query.blokkliEditing || route.query.blokkliPreview) &&
    drupalUser.value.accessToolbar,
)

const isMenuOpen = ref(false)
const hasMenuFinishedClosing = ref(true)

const data = await useInitData()

const showBreadcrumb = computed(
  () =>
    route.meta.hideBreadcrumb !== true &&
    !(
      route.meta.name === 'drupal-route' || route.meta.name === 'node-canonical'
    ),
)

const domain = computed(() => data.value?.siteInfo?.domain)

const styles = await useCurrentStyles()

function menuOpen() {
  isMenuOpen.value = true
}

function menuCloseStart() {
  if (isLessThanLg) {
    isMenuOpen.value = false
    hasMenuFinishedClosing.value = false
  }
}

function menuCloseFinish() {
  if (isLessThanLg) {
    hasMenuFinishedClosing.value = true
  }
}

useHead({
  htmlAttrs: {
    lang: language.value,
    style: styles,
  },
  link: [
    {
      rel: 'apple-touch-icon',
      href: `/favicons/${domain.value}/apple-touch-icon.png`,
    },
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: `/favicons/${domain.value}/favicon.ico`,
    },
    {
      rel: 'icon',
      type: 'image/svg+xml',
      href: `/favicons/${domain.value}/favicon.svg`,
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: `/favicons/${domain.value}/favicon-96x96.png`,
    },
    { rel: 'manifest', href: `/favicons/${domain.value}/site.webmanifest` },
    {
      rel: 'icon',
      type: 'image/png',
      href: `/favicons/${domain.value}/web-app-manifest-192x192.png`,
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: `/favicons/${domain.value}/web-app-manifest-512x512.png`,
    },
    {
      rel: 'mask-icon',
      href: `/favicons/${domain.value}/safari-pinned-tab.svg`,
      color: '#5bbad5',
    },
  ],
})
</script>
