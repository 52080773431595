<template>
  <div
    v-click-away="clickAway"
    class="p-2 h-10 md:h-20 flex items-center lg:justify-between w-full transition-all ease-in-out duration-250 cursor-pointer hover:text-primary-500"
    @click="subtreeOpen = !subtreeOpen"
  >
    {{ link.link.label }}
    <SpriteSymbol
      name="chevron-down-marketing"
      class="transition-all ease-in-out duration-250 size-4 ml-2 md:rotate-0 -rotate-90"
      :class="{
        'md:rotate-180': subtreeOpen,
      }"
    />
  </div>
  <div
    v-if="link.subtree"
    :class="{
      '-translate-x-full md:translate-x-0 md:overflow-hidden md:scale-y-0':
        !subtreeOpen,
      'translate-x-0 h-full md:scale-y-100': subtreeOpen,
    }"
    class="transition-all ease-in-out duration-500 pointer-events-none md:pointer-events-auto bg-header-footer-background size-full md:h-auto absolute top-0 left-0 md:top-20 md:origin-top z-[100] md:w-[calc(100%+50px)]"
  >
    <div
      v-if="subtreeOpen && isLessThanMd"
      class="cursor-pointer px-4 py-2 -mt-10"
      @click="subtreeOpen = !subtreeOpen"
    >
      <SpriteSymbol
        name="arrow-left"
        class="size-7 text-primary-500 pointer-events-auto"
      />
    </div>
    <ul
      v-if="link.subtree"
      class="pt-22 ml-10 md:pt-0 md:ml-0 h-full pointer-events-auto"
    >
      <li
        v-for="(subLink, j) in link.subtree"
        :key="`subLink_${j}`"
        class="flex items-stretch w-auto md:max-w-[175px] grow"
      >
        <VuepalLink
          :to="subLink.link?.url?.path"
          class="flex items-center transition-all ease-in-out duration-250 hover:text-primary-500 w-full md:size-auto h-[50px] py-2 px-4 md:px-3 pl-2"
        >
          {{ subLink.link.label }}
        </VuepalLink>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  linkIndex: number
}>()

const data = await useInitData()
const menuLinks = data.value.mainMenuLinks
const link = menuLinks[props.linkIndex]

const { isLessThanMd } = useViewport()

const subtreeOpen = ref(false)
const clickAway = () => {
  if (subtreeOpen.value) {
    subtreeOpen.value = false
  }
}
</script>
