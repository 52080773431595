<template>
  <div
    v-if="languageLinks.length !== 0"
    class="p-0 text-header-footer-text md:pr-4 z-10"
    :class="{
      'sticky top-0': isSticky,
    }"
  >
    <ClientOnly v-if="isLessThanMd">
      <div>
        <ul
          class="bg-header-footer-background transition-all ease-in-out duration-250 overflow-hidden flex text-lg flex-row items-center justify-center uppercase"
        >
          <li class="mx-4 border-b-2 border-b-header-footer-text mt-0.5">
            {{ currentLanguage[0].toUpperCase() + currentLanguage.slice(1) }}
          </li>
          <li v-for="(lang, i) in languageLinks" :key="`lang_${i}`">
            <a
              :href="lang.to"
              class="flex items-center py-2 px-4 pl-2 transition-all ease-in-out duration-250 hover:text-primary-500 cursor-pointer text-base text-header-footer-text"
            >
              {{ lang.code[0].toUpperCase() + lang.code.slice(1) }}
            </a>
          </li>
        </ul>
      </div>
    </ClientOnly>
    <ClientOnly v-else>
      <div
        v-click-away="clickAway"
        class="transition-all ease-in-out duration-250 min-w-[60px] h-10 md:h-20"
      >
        <button
          class="p-2 h-10 md:h-20 flex items-center lg:justify-between w-full transition-all ease-in-out duration-250 hover:text-primary-500 text-base md:font-medium text-header-footer-text"
          :disabled="languageLinks.length === 0"
          @click="langSwitchDropdownOpen = !langSwitchDropdownOpen"
        >
          <span class="pr-2">
            {{
              currentLanguage[0].toUpperCase() + currentLanguage.slice(1)
            }}</span
          >
          <SpriteSymbol
            v-if="languageLinks.length !== 0"
            name="chevron-down-marketing"
            class="size-4 transition-all ease-in-out duration-250"
            :class="{
              'rotate-180': langSwitchDropdownOpen,
            }"
          />
        </button>
        <ul
          :style="[
            `--target-height-mobile: ${languageLinks.length * 30}px`,
            `--target-height-desktop: ${languageLinks.length * 40}px`,
          ]"
          :class="{
            'h-0 z-[100]': !langSwitchDropdownOpen,
            'h-[var(--target-height-mobile)] md:h-[var(--target-height-desktop)] z-[100]':
              langSwitchDropdownOpen,
          }"
          class="bg-header-footer-background transition-all ease-in-out duration-250 overflow-hidden"
        >
          <li v-for="(lang, i) in languageLinks" :key="`lang_${i}`">
            <a
              :href="lang.to"
              class="flex items-center py-2 px-4 md:px-3 pl-2 transition-all ease-in-out duration-250 hover:text-primary-500 cursor-pointer text-base md:font-medium text-header-footer-text"
            >
              {{ lang.code[0].toUpperCase() + lang.code.slice(1) }}
            </a>
          </li>
        </ul>
      </div>
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
import type { ComputedRef } from 'vue'

defineProps<{
  isHeaderShiftedUp: boolean
  isSticky: boolean
}>()

type LanguageLink = {
  code: string
  active: boolean
  to: string
}

const languages: ComputedRef<LanguageLink[]> = useLanguageLinks()
const currentLanguage = useCurrentLanguage()
const data = await useInitData()

// Remove the languages that are not available in data.value.siteInfo.availableLanguages from the languages array
const availableLanguages =
  data.value.siteInfo?.availableLanguages ?? ([] as string[])

const languageLinks = computed(() => {
  return languages.value.filter(
    (l) =>
      l.code !== currentLanguage.value && availableLanguages.includes(l.code),
  )
})

const { isLessThanMd } = useViewport()

const langSwitchDropdownOpen = ref(false)
const clickAway = () => {
  if (langSwitchDropdownOpen.value) {
    langSwitchDropdownOpen.value = false
  }
}
</script>
