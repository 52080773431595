<template>
  <div
    class="bg-header-footer-background text-header-footer-text pt-11 pb-16 md:py-20"
  >
    <div class="container">
      <div
        class="pb-8 flex items-center justify-center md:justify-start h-full"
      >
        <nuxt-link :to="{ name: 'home' }">
          <MediaImage v-bind="usedFooterLogo" :image-style="logoStyle" />
        </nuxt-link>
      </div>

      <div class="grid gap-11 md:gap-1 md:grid-cols-3">
        <div>
          <h2 class="md:pb-5 font-bold">
            {{ $texts('footerMarketing.contactTitle', 'Kontaktanfrage') }}
          </h2>
          <div v-html="address?.replace(/\n/g, '<br />')" />
          <div>
            <a :href="`mailto:` + email" class="hover:text-primary-500">{{
              email
            }}</a>
          </div>
          <div>
            <a :href="`tel:` + phone" class="hover:text-primary-500">
              {{ phone }}
            </a>
          </div>

          <div class="md:hidden">
            <ul
              v-if="socialMediaLinks"
              class="flex flex-wrap gap-8 mt-10 md:mt-12"
            >
              <li
                v-for="(link, i) in socialMediaLinks"
                :key="`social_link_${i}`"
                class="flex items-center justify-center rounded-full text-button-text size-10 bg-primary-500 hover:bg-primary-600 transition-all ease-in-out duration-250"
              >
                <nuxt-link :to="link.path" :aria-label="link.iconName">
                  <SpriteSymbol
                    :alt="link.iconName"
                    :name="link.iconName"
                    class="size-6 flex scale-75"
                  />
                </nuxt-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="hidden md:flex md:flex-col">
          <h2 class="md:pb-5 font-bold">
            {{ $texts('footerMarketing.directLinks', 'Direktlinks') }}
          </h2>
          <div v-for="(link, i) in links" :key="i">
            <nuxt-link :to="link.uri?.path" class="hover:text-primary-500">
              {{ link.title }}
            </nuxt-link>
          </div>
        </div>
        <div>
          <h2 class="md:pb-5 font-bold">
            {{ $texts('footerMarketing.aboutUs', 'Über uns') }}
          </h2>
          <div
            class="mb-8 md:mb-14"
            v-html="aboutUs?.replace(/\n/g, '<br />')"
          />
          <PageHeaderMarketingContactMenu
            :is-menu-open="isMenuOpen"
            :class="{}"
          />
        </div>
      </div>
      <div class="mt-4 hidden md:block">
        <ul v-if="socialMediaLinks" class="flex flex-wrap gap-8 mt-10 md:mt-12">
          <li
            v-for="(link, i) in socialMediaLinks"
            :key="`social_link_${i}`"
            class="flex items-center justify-center rounded-full text-button-text size-10 bg-primary-500 hover:bg-primary-600 transition-all ease-in-out duration-250"
          >
            <nuxt-link :to="link.path" :aria-label="link.iconName">
              <SpriteSymbol
                :alt="link.iconName"
                :name="link.iconName"
                class="size-6 flex scale-75"
              />
            </nuxt-link>
          </li>
        </ul>
      </div>

      <PageFooterMarketingFooterLinks in-footer />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { NuxtSvgSpriteSymbol } from '#nuxt-svg-sprite/runtime'
import { notNullish } from '@vueuse/core'

const data = await useInitData()
const { $texts } = useEasyTexts()
const address = data.value.siteInfo.address
const email = data.value.siteInfo.email
const phone = data.value.siteInfo.phoneNumber
const links = data.value.siteInfo.footerLinks
const aboutUs = data.value.siteInfo.aboutUs
const socialLinks = data.value.siteInfo.socialMediaLinks
const logo = data.value.siteInfo.logo
const footerLogo = data.value.siteInfo.footerLogo
const isMenuOpen = ref(false)

const logoStyle = defineImageStyle({
  type: 'pictures',
  pictures: {
    xs: {
      width: 215,
    },
  },
})

const usedFooterLogo = footerLogo ? footerLogo : logo

const socialMediaLinks = computed(() => {
  return socialLinks
    ?.map((link: any) => {
      const path = link?.uri?.path
      if (!path) return null
      const iconName = getIconName(path)
      if (!iconName) return null
      return {
        path,
        iconName,
      }
    })
    .filter(notNullish)
})

function getIconName(url = ''): NuxtSvgSpriteSymbol | null {
  if (url.includes('twitter.com') || url.includes('x.com')) {
    return 'twitter'
  } else if (url.includes('facebook.com')) {
    return 'facebook'
  } else if (url.includes('linkedin.com')) {
    return 'linkedin'
  } else if (url.includes('instagram.com')) {
    return 'instagram'
  } else if (url.includes('youtube.com')) {
    return 'youtube'
  }
  return null
}
</script>
