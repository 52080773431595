<template>
  <nav
    class="text-header-footer-text md:px-10 pb-8 md:pb-0 transition-all ease-in-out duration-250 relative"
  >
    <ul
      class="flex flex-col pt-24 md:pt-0 md:flex-row gap-4 md:gap-2 h-full text-2xl md:text-base md:font-medium text-header-footer-text"
    >
      <li
        v-for="(link, i) in menuLinks"
        :key="`global_${i}`"
        class="flex items-stretch px-8 md:px-0"
      >
        <div
          v-if="link.subtree.length > 0"
          class="transition-all ease-in-out duration-250 min-w-[60px] md:relative"
        >
          <GlobalMenuLinkGroup :link-index="i" />
        </div>

        <div
          v-else
          class="h-10 md:h-20 flex items-center hover:text-primary-500 lg:justify-between min-w-[60px]"
        >
          <VuepalLink
            :to="link.link?.url?.path"
            class="flex items-center transition-all ease-in-out duration-250 w-full md:size-auto h-[50px] px-4 md:px-3 pl-2"
          >
            {{ link.link.label }}
          </VuepalLink>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import GlobalMenuLinkGroup from '~/components/PageHeaderMarketing/GlobalMenuLinkGroup.vue'

const data = await useInitData()
const menuLinks = data.value.mainMenuLinks
</script>
