<template>
  <header
    ref="container"
    class="grid page-header-grid transition-all ease-in-out duration-250 top-0 z-10 bg-header-footer-background md:h-20"
    :class="{
      '-translate-y-10 md:translate-y-[-50px]':
        isHeaderShiftedUp && !isMenuOpen,
      'h-12 md:max-h-screen': !isMenuOpen,
      'overflow-y-auto h-screen': isMenuOpen,
      'fixed w-full': isGlobalSticky,
      sticky: !isGlobalSticky,
    }"
  >
    <div class="grid-area-logo h-7 md:h-20">
      <div
        class="pl-4 md:pl-24 pr-1 flex items-center text-header-footer-text transition-all ease-in-out duration-250 h-full pt-5 md:pt-0"
        :class="{
          'sticky top-0': isGlobalSticky,
        }"
      >
        <nuxt-link
          :to="{ name: 'home' }"
          :class="{
            hidden: isMenuOpen,
          }"
        >
          <MediaImage v-bind="image" :image-style="logoStyle" />
        </nuxt-link>
      </div>
    </div>
    <div
      class="transition-all ease-in-out duration-250 md:text-lg px-4 md:px-0 flex items-center justify-between grid-area-hamburger pt-2 md:pt-0"
      @click.prevent="toggleMenu"
    >
      <button class="md:hidden flex items-center gap-3 leading-none">
        <PageHeaderMenuIcon :is-in-closable-state="isMenuOpen" />
      </button>
    </div>

    <PageHeaderMarketingGlobalMenu
      v-if="showMenu"
      ref="globalMenu"
      class="grid-area-global"
    />
    <PageHeaderMarketingLanguageSwitch
      v-if="showMenu"
      ref="langSwitch"
      :is-sticky="isGlobalSticky"
      :is-header-shifted-up="isHeaderShiftedUp"
      class="grid-area-language"
      :class="{
        hidden: languageLinks?.length === 0,
      }"
    />
    <PageHeaderMarketingContactMenu
      v-if="showMenu"
      :is-menu-open="isMenuOpen"
      class="grid-area-contact h-10 md:h-20 mb-8 md:mb-0 px-10 md:px-0"
      :class="{}"
    />
    <PageFooterMarketingFooterLinks
      v-if="showMenu"
      :in-footer="false"
      class="grid-area-impressum md:hidden"
    />
  </header>
</template>

<script setup lang="ts">
import type { SiteLogoFragment } from '#graphql-operations'
import { checkIfRefIsNotNull } from '~/helpers/checkIfRefIsNotNull'

const route = useRoute()
const isHeaderShiftedUp = ref(false)
const container = ref<HTMLElement | null>(null)
const isGlobalSticky = ref(false)
const isMenuOpen = ref(false)
const data = await useInitData()
const { isLessThanLg, isLessThanMd } = useViewport()
const languageLinks = data?.value?.siteInfo?.availableLanguages
const showMenu = computed(() => {
  return !isLessThanMd.value || isMenuOpen.value
})

let timeout: number | null = null

watch(() => route.path, closeMenu)

defineProps<{
  image?: SiteLogoFragment
}>()

const logoStyle = defineImageStyle({
  type: 'pictures',
  pictures: {
    xs: {
      width: 150,
    },
  },
})

function toggleMenu() {
  if (isMenuOpen.value) {
    closeMenu()
  } else {
    openMenu()
  }
}

function closeMenu() {
  if (timeout) {
    clearTimeout(timeout)
  }
  isMenuOpen.value = false
  if (checkIfRefIsNotNull<HTMLElement>(container)) {
    // We need to manually reset the scroll position inside the menu container,
    // so it doesn't
    container.value.scrollTop = 0
  }

  timeout = window.setTimeout(() => {
    isGlobalSticky.value = false
  }, 250)
}

function openMenu() {
  if (isLessThanLg.value) {
    isMenuOpen.value = true
    isGlobalSticky.value = true
    isHeaderShiftedUp.value = false
  }
}
</script>

<style lang="postcss">
.page-header-grid {
  grid-template:
    'logo hamburger' theme(space.10)
    'global global' 1fr
    'language language' auto
    'impressum impressum' auto
    'contact contact' auto
    / 1fr auto;

  @screen md {
    grid-template: 'logo global language contact' auto / auto 1fr auto auto;
  }
}

.grid-area-logo {
  grid-area: logo;
}

.grid-area-hamburger {
  grid-area: hamburger;
}

.grid-area-global {
  grid-area: global;
}

.grid-area-language {
  grid-area: language;
}

.grid-area-impressum {
  grid-area: impressum;
}

.grid-area-contact {
  grid-area: contact;
}

.grid-area-logo img {
  @apply h-7 md:h-12 w-auto;
}
</style>
